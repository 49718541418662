import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { TreeView } from '@material-ui/lab';
import { DocumentTemplateType } from '../../api-client/autogenerated';
import { AddBoxOutlined, IndeterminateCheckBoxOutlined, Search } from '@material-ui/icons';
import DocumentTypeTreeItem from './DocumentTypeTreeItem';
import CircularLoader from '../loader/CircularLoader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { DocumentFileTree, generateUniqueId } from '../../scripts/utils';
import { useDispatch, useSelector } from 'react-redux';
import { reloadDocument } from '../../features/document/actions';
import { modifyDocumentById } from '../../models/api/documents';
import { getDocumentState } from '../../features/document/selectors';
import { addSnackbar } from '../../features/snackbar/actions';

type Props = {
  documents: DocumentFileTree[];
  setDocuments: (documents: DocumentFileTree[]) => void;
  open: boolean;
  handleClose: () => void;
  onComplete?: () => void;
  documentId?: string;
  title?: string;
  subtitle?: string;
};

const useStyles = makeStyles(() => ({
  textfield: {
    height: '32px',

    /* Gray / Gray 50 */
    background: '#F9F9F9',

    /* Gray / Gray 400 Brand dark */
    border: '1px solid #949494',
    'border-radius': '5px',
  },
  icon: {
    fill: '#B2B1B2',
    marginLeft: '4px',
  },
}));

export default function AssociateDocumentDialog(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    open,
    handleClose,
    documents,
    setDocuments,
    documentId,
    onComplete,
    title,
    subtitle,
  } = props;

  const document = useSelector(getDocumentState);

  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [documentsToAssociate, setDocumentsToAssociate] = useState<string[]>([]);

  useEffect(() => {
    setDocumentsToAssociate(
      documents
        .map((tree) => tree.documents.filter((doc) => doc.checked).map((doc) => doc.id))
        .flat(),
    );
  }, [documents]);

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    type: DocumentTemplateType,
  ) => {
    const newDocuments = [...documents];
    newDocuments
      .find((tree) => tree.type === type)!
      .documents.forEach((doc) => (doc.checked = checked));
    setDocuments(newDocuments);
  };

  const handleSubCheck = (checked: boolean, type: string, id: string) => {
    const newDocuments = [...documents];
    newDocuments
      .find((tree) => tree.type === type)!
      .documents.find((doc) => doc.id === id)!.checked = checked;
    setDocuments(newDocuments);
  };

  const handleDialogClose = async () => {
    if (documentsToAssociate.length > 0 && documentId) {
      await modifyDocumentById(documentId, {
        associatedDocuments: { addIds: documentsToAssociate },
      });

      if (document) {
        dispatch(reloadDocument());
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Successfully added new associations',
            severity: 'success',
          }),
        );
      }
    }
    onComplete?.();
    handleClose();
  };

  useEffect(() => {
    if (documents.length > 0) setIsLoading(false);
    else setIsLoading(true);
  }, [documents]);

  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{ style: { maxWidth: '720px' } }}
      onClose={handleClose}
    >
      <DialogTitle
        disableTypography
        style={{ display: 'flex', alignItems: 'center', paddingBottom: 8 }}
      >
        <Typography variant="h1" style={{ textTransform: 'none', lineHeight: 1 }}>
          {title || 'Associate Existing Documents'}
        </Typography>
        <div style={{ display: 'inline-flex', flexGrow: 100 }} />
        <Button
          id="document-done-btn"
          color="primary"
          variant="contained"
          onClick={handleDialogClose}
        >
          Done
        </Button>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 16 }}>
          {subtitle ||
            'Centerline allows you to associate new documents with existing documents on the project ' +
              "for cross-referencing. Associating an existing document automatically adds that document's " +
              'users to the notification list of the new document.'}
        </Typography>
        <FormControl variant="outlined">
          <TextField
            className={classes.textfield}
            placeholder="Search..."
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search
                    fontSize="small"
                    classes={{
                      root: classes.icon,
                    }}
                  />
                </InputAdornment>
              ),
            }}
            style={{ marginBottom: 12 }}
          />
        </FormControl>
        {isLoading ? (
          <CircularLoader />
        ) : (
          <TreeView
            defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
            defaultExpandIcon={<AddBoxOutlined />}
          >
            {documents
              .map(({ type, documents }) => {
                return {
                  type: type,
                  documents: searchInput
                    ? documents.filter((doc) => doc.title.includes(searchInput))
                    : documents,
                };
              })
              .filter((doc) => doc.documents.length > 0)
              .map((doc) => {
                return (
                  <DocumentTypeTreeItem
                    key={generateUniqueId()}
                    tree={doc}
                    handleCheck={handleCheck}
                    handleSubCheck={handleSubCheck}
                  />
                );
              })}
          </TreeView>
        )}
      </DialogContent>
    </Dialog>
  );
}
