import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Drawer from '@material-ui/core/Drawer';
import { AppBar, CssBaseline, Toolbar, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { EmailOutlined, ExitToApp } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import DashboardIcon from '../icons/Dashboard-icon';
import BiddingIcon from '../icons/Bidding-icon';
import DesignIcon from '../icons/Design-icon';
import ConstructionIcon from '../icons/Construction-icon';
import DrawerListItem from './DrawerListItem';
import DrawerNestedList from './DrawerNestedList';
import './NavDrawer.scss';
import DashboardLockup from '../icons/Dashboard-lockup';
import { Auth } from '@aws-amplify/auth';
import { setUser } from '../../features/user/actions';
import getProjectsState from '../../features/projects/selectors';
import { setProject } from '../../features/project/actions';
import { getProjectState } from '../../features/project/selectors';
import { getUserState } from '../../features/user/selectors';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import CloseOutIcon from '../icons/CloseOut-icon';
import {
  canViewBidding,
  canViewConstructionAndCloseout,
  canViewDesign,
  isPdfViewer,
  isPublicPage,
} from '../../scripts/utils';
import { hasCompletedConstructionSetup } from '../../scripts/store-utils';
import { getProductPackageState } from '../../features/product-package/selectors';
import { DocumentTemplateType, ISimplifiedProjectView } from '../../api-client/autogenerated';
import { getDocumentsType } from '../../features/documents/selectors';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';

export const PRIMARY_NAVIGATION_WIDTH_OPEN = 230;
export const PRIMARY_NAVIGATION_WIDTH_CLOSED = 60;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: PRIMARY_NAVIGATION_WIDTH_OPEN,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    overflowX: 'hidden',
    width: PRIMARY_NAVIGATION_WIDTH_OPEN,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('xs')]: {
      width: PRIMARY_NAVIGATION_WIDTH_CLOSED,
    },
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  toolbarClosed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  toolbarOpen: {
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  // align logo to arrow
  marginAutoItem: {
    marginBottom: 35,
    marginTop: 30,
    marginRight: 1,
    position: 'absolute',
  },
  selectAnchor: {
    position: 'relative',
    top: 20,
  },
  formControl: {
    display: 'flex',
    width: '100%',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  drawerArrow: {
    color: '#A6C4FF',
    textAlign: 'right',
  },
  drawerSelect: {
    height: '32px',
    /* Gray / Gray 50 */
    background: '#F9F9F9',

    /* Gray / Gray 400 Brand dark */
    border: '1px solid #949494',
    'border-radius': '5px',
  },
  drawerDivider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  menuPaper: {
    maxHeight: 400,
    maxWidth: 300,
    overflowWrap: 'break-word',
    whiteSpace: 'unset',
  },
  wrap: {
    whiteSpace: 'unset',
    overflowWrap: 'break-word',
  },
  dropdownPadding: {
    paddingTop: 40,
  },
  exitButton: {
    margin: 'auto 0px 12px 5px',
    alignSelf: 'flex-start',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '25px',
    textTransform: 'none',
    color: '#CCDEFF',
  },
  hidden: {
    display: 'none',
    width: 0,
  },

  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${PRIMARY_NAVIGATION_WIDTH_OPEN}px)`,
      marginLeft: PRIMARY_NAVIGATION_WIDTH_OPEN,
    },
    background: '#00308C',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  searchBarIcon: {
    marginRight: -6,
  },
  searchBarPopper: {
    minWidth: '200px !important',
    maxWidth: '300px !important',
    width: 'unset !important',
    boxShadow: '3px 5px 5px 2px rgba(0, 0, 0, 0.20)',
    marginLeft: 6,
    marginTop: -4,
  },
  hideClearIcon: {
    display: 'none',
  },
}));

type Props = {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function NavDrawer(props: Props) {
  const classes = useStyles();
  const { drawerOpen, setDrawerOpen } = props;

  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentProject = useSelector(getProjectState);
  const user = useSelector(getUserState);
  const projects = useSelector(getProjectsState);
  const security = useSelector(getCurrentSecurityGroup);
  const productPackage = useSelector(getProductPackageState);

  const [selectProjectInput, setSelectProjectInput] = useState('');
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [emailMenuOpen, setEmailMenuOpen] = useState(false);
  const [designMenuOpen, setDesignMenuOpen] = useState(false);
  const [biddingMenuOpen, setBiddingMenuOpen] = useState(false);
  const [closeOutMenuOpen, setCloseOutMenuOpen] = useState(false);
  const [constructionMenuOpen, setConstructionMenuOpen] = useState(false);
  const [drawerIndex, setDrawerIndex] = useState(-1);
  const [favoriteProjectIds, setFavoriteProjectIds] = useState<string[]>([]);
  const documentType = useSelector(getDocumentsType);

  const pathnames = location.pathname.split('/').filter((x) => {
    if (documentType === DocumentTemplateType.PunchList)
      return x && x !== 'edit' && x !== 'edit-location';
    return x;
  });

  useEffect(() => {
    if (user) {
      setFavoriteProjectIds(
        user.projectUserList
          ?.filter((projectUser) => projectUser.isFavorite)
          .map((projectUser) => projectUser.projectId) || [],
      );
    }
  }, [user]);

  const projectSort = (a: ISimplifiedProjectView, b: ISimplifiedProjectView) => {
    const name1 = a.name.toLowerCase();
    const name2 = b.name.toLowerCase();
    const isFavorite1 = favoriteProjectIds.includes(a.id);
    const isFavorite2 = favoriteProjectIds.includes(b.id);
    if (isFavorite1 && !isFavorite2) return -1;
    if (isFavorite2 && !isFavorite1) return 1;
    if (name1 < name2) return -1;
    if (name1 > name2) return 1;
    return 0;
  };

  const emailMenuItems = ['My Notifications'];
  if (
    currentProject?.emailSlug &&
    ((security?.correspondenceInboxPermission !== null &&
      security?.correspondenceInboxPermission !== undefined) ||
      user.isSiteAdmin ||
      user.adminOfSubscriberId === currentProject?.subscriberId)
  ) {
    emailMenuItems.push('Correspondence Log');
  }

  const designMenuItems = ['Task & Share Manager', 'Design Package Review'];
  if (canViewDesign(productPackage, security, user, currentProject)) {
    designMenuItems.splice(0, 0, 'File Manager');
  }

  const biddingMenuItems = currentProject?.bidSetupId
    ? [
        'Bid Drawings',
        'Project Manual',
        'Addenda',
        'Informational Items',
        'Bidder RFIs',
        'Substitution Requests',
        // 'Regulatory Approvals',
        'Centerline BidConnect',
      ]
    : [];
  if (
    currentProject?.bidSetupId &&
    (user.isSiteAdmin ||
      user.adminOfSubscriberId ||
      (security && security.planholderListPermission! > 0))
  )
    biddingMenuItems.splice(0, 0, 'Bid Management');
  else if (
    user.isSiteAdmin ||
    user.adminOfSubscriberId ||
    (security && security.bidManagementPermission! > 0)
  )
    biddingMenuItems.splice(0, 0, 'Bid Setup');
  if (
    currentProject?.bidSetupId &&
    (user.isSiteAdmin ||
      user.adminOfSubscriberId ||
      (security && security.planholderListPermission! > 0))
  )
    biddingMenuItems.splice(biddingMenuItems.length - 3, 0, 'Planholder List');
  if (
    currentProject?.bidSetupId &&
    (user.isSiteAdmin ||
      user.adminOfSubscriberId ||
      (security && security.bidTabulationPermission! > 0))
  )
    biddingMenuItems.splice(biddingMenuItems.length - 1, 0, 'Bid Submissions');

  const constructionMenuItems =
    currentProject && hasCompletedConstructionSetup()
      ? [
          'Drawings',
          'Specifications',
          'ASI Documents',
          'Submittals',
          'Requests for Information',
          'Change Orders',
          'Const. Change Directives',
          'Potential Change Orders',
          'Requests for Change',
          'Work Change Proposal Req.',
          'Pay Applications',
          'Field Reports',
          'Meeting Minutes',
          'Contractor Daily Logs',
          'Miscellaneous Documents',
          'Schedules',
          'Testing',
        ]
      : [];
  if (
    hasCompletedConstructionSetup() &&
    (user.isSiteAdmin ||
      user.adminOfSubscriberId === currentProject?.subscriberId ||
      (security && security.constructionManagementPermission! > 0))
  )
    constructionMenuItems.splice(0, 0, 'Construction Management');
  else if (
    user.isSiteAdmin ||
    user.adminOfSubscriberId === currentProject?.subscriberId ||
    (security && security.constructionManagementPermission! > 0)
  )
    constructionMenuItems.splice(0, 0, 'Construction Setup');
  if (
    hasCompletedConstructionSetup() &&
    (user.isSiteAdmin ||
      user.adminOfSubscriberId === currentProject?.subscriberId ||
      (security && security.publishingCenterPermission && security.publishingCenterPermission > 0))
  ) {
    const canSeeConstructionManagement =
      constructionMenuItems.indexOf('Construction Management') !== -1;
    constructionMenuItems.splice(canSeeConstructionManagement ? 1 : 0, 0, 'Publishing Center');
  }

  const closeOutMenuItems =
    currentProject && currentProject.defaultArchitectUserId
      ? [
          'Punch List',
          'As-Built Drawings',
          'Closeout Submittals',
          'O&M Data',
          'Warranty Items',
          'Clear L&P',
          'Substantial Completion Cert.',
        ]
      : [];

  const openDrawerIcon =
    theme.direction === 'rtl' ? (
      <ArrowBackIcon className={classes.drawerArrow} />
    ) : (
      <ArrowForwardIcon className={classes.drawerArrow} />
    );
  const closeDrawerIcon =
    theme.direction === 'rtl' ? (
      <ArrowForwardIcon className={classes.drawerArrow} />
    ) : (
      <ArrowBackIcon className={classes.drawerArrow} />
    );

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleProjectSelect = (projectId: string) => {
    setDrawerIndex(-1);
    if (projectId === 'none') {
      setSelectedProjectId(projectId);
      dispatch(setProject());
      history.push('/main');
    } else if (projectId === 'create') {
      setSelectedProjectId('');
      dispatch(setProject());
      history.push('/main/project');
    } else {
      setSelectedProjectId(projectId);
      history.push(`/main/projects/${projectId}`);
    }
  };

  const toggleEmailMenu = () => {
    setDrawerOpen(true);
    setEmailMenuOpen(!drawerOpen ? true : !emailMenuOpen);
    setDesignMenuOpen(false); // set true if drawer is closed
    setBiddingMenuOpen(false);
    setConstructionMenuOpen(false);
    setCloseOutMenuOpen(false);
  };

  const toggleDesignMenu = () => {
    setDrawerOpen(true);
    setDesignMenuOpen(!drawerOpen ? true : !designMenuOpen); // set true if drawer is closed
    setBiddingMenuOpen(false);
    setConstructionMenuOpen(false);
    setEmailMenuOpen(false);
    setCloseOutMenuOpen(false);
  };

  const toggleBiddingMenu = () => {
    setDrawerOpen(true);
    setBiddingMenuOpen(!drawerOpen ? true : !biddingMenuOpen); // set true if drawer is closed
    setCloseOutMenuOpen(false);
    setConstructionMenuOpen(false);
    setDesignMenuOpen(false);
    setEmailMenuOpen(false);
  };

  const toggleCloseOutMenu = () => {
    setDrawerOpen(true);
    setCloseOutMenuOpen(!drawerOpen ? true : !closeOutMenuOpen); // set true if drawer is closed
    setBiddingMenuOpen(false);
    setConstructionMenuOpen(false);
    setDesignMenuOpen(false);
    setEmailMenuOpen(false);
  };

  const toggleConstructionMenu = () => {
    setDrawerOpen(true);
    setConstructionMenuOpen(!drawerOpen ? true : !constructionMenuOpen); // set true if drawer is closed
    setBiddingMenuOpen(false);
    setCloseOutMenuOpen(false);
    setDesignMenuOpen(false);
    setEmailMenuOpen(false);
  };

  const handleDrawerItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    //setDrawerIndex(index);
  };

  const initiateSelectedIndex = (setIndex: (index: number) => void, area: string) => {
    const emailMenuItems = currentProject ? ['my-notifications'] : [];
    if (currentProject?.emailSlug) {
      emailMenuItems.push('correspondence-log');
    }
    const designMenuItems = ['design', 'tasks', 'design-packages'];
    const biddingMenuItems = [
      'bid-mgmt',
      'bid-drawings',
      'project-manual',
      'addenda',
      'info-items',
      'substitution-requests',
      'bidder-rfis',
      'planholder-list',
      'bid-tabulation',
      'regulatory-approvals',
    ];
    const constructionMenuItems = [
      'const-mgmt',
      'pub-center',
      'drawings',
      'specifications',
      'asi-docs',
      'submittals',
      'rfi',
      'change-orders',
      'const-change-directives',
      'potential-change-orders',
      'rfc',
      'work-change-prop-requests',
      'pay-applications',
      'field-reports',
      'meeting-minutes',
      'daily-logs',
      'misc-docs',
      'schedules',
      'testing',
    ];
    const closeoutMenuItems = [
      'punch-list',
      'as-built',
      'closeout-submittals',
      'om-data',
      'clear-lp',
      'substantial-completion-cert',
    ];

    if (pathnames.length > 1 && area === 'sidebar') {
      let lastPathName = pathnames[pathnames.length - 1];
      let innerPathName = pathnames[pathnames.length - 2];
      //need a special one for 1) drawings, 2) specs, 3) asi docs, 4) submittals, 5) rfi, 6) Change orders,

      if (emailMenuItems.includes(lastPathName) || emailMenuItems.includes(innerPathName))
        setIndex(2);
      else if (designMenuItems.includes(lastPathName) || designMenuItems.includes(innerPathName))
        setIndex(3);
      else if (biddingMenuItems.includes(lastPathName) || biddingMenuItems.includes(innerPathName))
        setIndex(4);
      else if (
        constructionMenuItems.includes(lastPathName) ||
        constructionMenuItems.includes(innerPathName)
      )
        setIndex(5);
      else if (
        closeoutMenuItems.includes(lastPathName) ||
        closeoutMenuItems.includes(innerPathName)
      )
        setIndex(6);
    } else if (pathnames.length > 1 && area === 'items') {
      let lastPathName = pathnames[pathnames.length - 1];
      let innerPathName = pathnames[pathnames.length - 2];
      if (emailMenuItems.includes(lastPathName)) setIndex(emailMenuItems.indexOf(lastPathName));
      else if (designMenuItems.includes(lastPathName))
        setIndex(designMenuItems.indexOf(lastPathName));
      else if (biddingMenuItems.includes(lastPathName))
        setIndex(biddingMenuItems.indexOf(lastPathName));
      else if (constructionMenuItems.includes(lastPathName))
        setIndex(constructionMenuItems.indexOf(lastPathName));
      else if (closeoutMenuItems.includes(lastPathName))
        setIndex(closeoutMenuItems.indexOf(lastPathName));
      else if (emailMenuItems.includes(innerPathName))
        setIndex(emailMenuItems.indexOf(innerPathName));
      else if (designMenuItems.includes(innerPathName))
        setIndex(designMenuItems.indexOf(innerPathName));
      else if (biddingMenuItems.includes(innerPathName))
        setIndex(biddingMenuItems.indexOf(innerPathName));
      else if (constructionMenuItems.includes(innerPathName))
        setIndex(constructionMenuItems.indexOf(innerPathName));
      else if (closeoutMenuItems.includes(innerPathName))
        setIndex(closeoutMenuItems.indexOf(innerPathName));
    }
  };

  const handleDashboardClick = () => {
    history.push(`/main${currentProject ? `/projects/${currentProject.id}` : ''}`);
    setBiddingMenuOpen(false);
    setConstructionMenuOpen(false);
    setCloseOutMenuOpen(false);
    setDrawerIndex(-1);
  };

  const handleCalendarClick = () => {
    history.push(`/main${currentProject ? `/projects/${currentProject.id}` : ''}/calendar`);
    setBiddingMenuOpen(false);
    setConstructionMenuOpen(false);
    setCloseOutMenuOpen(false);
    setDrawerIndex(-1);
  };

  const handleMyNotificationsClick = () => {
    history.push('/main/my-notifications');
    setBiddingMenuOpen(false);
    setConstructionMenuOpen(false);
    setCloseOutMenuOpen(false);
    setDrawerIndex(-1);
  };

  useEffect(() => {
    setDrawerIndex(-1);
    if (currentProject) {
      setSelectedProjectId(currentProject.id);
      setSelectProjectInput(currentProject.name);
    } else {
      setSelectedProjectId('');
      setSelectProjectInput('');
    }
  }, [currentProject]);

  useEffect(() => {
    if (drawerOpen && history.location.pathname === '/main/project') {
      setSelectedProjectId('');
    }
  }, [drawerOpen, history]);

  useEffect(() => {
    initiateSelectedIndex(setDrawerIndex, 'sidebar');
  });

  const handleSignOut = () => {
    Auth.signOut()
      .then(() => {
        dispatch(setUser());
        dispatch(setProject());
        history.push('/main/login');
      })
      .catch((e) => console.log('error signing out: ', e));
  };

  const openedDrawer = (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <IconButton
          style={{ backgroundColor: 'transparent', paddingTop: '30px' }}
          onClick={handleDashboardClick}
        >
          <DashboardLockup style={{ width: '80px', height: '80px' }} />
        </IconButton>
        <div className={classes.toolbarOpen}>
          <IconButton
            id="arrow-button"
            onClick={toggleDrawer}
            style={{ background: 'transparent', alignSelf: 'baseline' }}
          >
            {closeDrawerIcon}
          </IconButton>
        </div>
      </div>
    </>
  );

  const closedDrawer = (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.toolbarClosed}>
          <IconButton
            id="arrow-button"
            onClick={toggleDrawer}
            style={{ background: 'transparent' }}
          >
            {openDrawerIcon}
          </IconButton>
        </div>
        <IconButton style={{ backgroundColor: 'transparent' }} onClick={handleDashboardClick}>
          <DashboardLockup style={{ width: '40px', height: '40px' }} />
        </IconButton>
      </div>
    </>
  );

  const projectOptions = [
    ...(!!currentProject ? [{ id: 'none', name: 'All Projects' }] : []),
    ...(user.isSiteAdmin || user.adminOfSubscriberId !== null
      ? [
          {
            id: 'create',
            name: 'Create New Project',
          },
        ]
      : []),
    ...projects.sort(projectSort),
  ];

  const drawer = (
    <>
      {drawerOpen ? openedDrawer : closedDrawer}
      <div className={classes.selectAnchor} />
      {drawerOpen && (
        <>
          <Typography
            style={{
              marginTop: 24,
              marginLeft: 16,
              color: '#CCDEFF',
              fontSize: 12,
            }}
          >
            Select a Project
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <Autocomplete
              size="small"
              options={projectOptions}
              getOptionLabel={(project) => project.name}
              getOptionSelected={(option, value) => option.id === value?.id}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={
                      !!selectProjectInput || !!selectedProjectId ? '' : 'Type to search by name'
                    }
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      shrink: false,
                      style: {
                        transform: 'translate(8px, 12px)',
                        background: 'transparent',
                        color: '#B2B1B2',
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      style: { paddingLeft: 3 },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      style: { paddingRight: 24 },
                    }}
                  />
                );
              }}
              renderOption={(project) => (
                <span
                  key={project.id}
                  className={classes.wrap}
                  style={{
                    fontWeight: favoriteProjectIds.includes(project.id) ? 'bold' : undefined,
                    width: '100%',
                  }}
                >
                  {project.name}
                </span>
              )}
              classes={{
                popupIndicator: classes.searchBarIcon,
                popper: classes.searchBarPopper,
                clearIndicator: classes.hideClearIcon,
              }}
              inputValue={selectProjectInput}
              value={projects.find((p) => p.id === selectedProjectId)}
              onInputChange={(event, value) => setSelectProjectInput(value)}
              onChange={(event, value) =>
                value ? handleProjectSelect(value.id) : console.log('no value', value)
              }
            />
          </FormControl>
        </>
      )}
      <Divider className={classes.drawerDivider} />
      <List style={{ padding: 0 }}>
        <DrawerListItem
          key={0}
          title="Dashboard"
          listIndex={0}
          onClick={handleDrawerItemClick}
          selectedIndex={drawerIndex}
          icon={<DashboardIcon />}
          drawerOpen={drawerOpen}
          handlePageChange={handleDashboardClick}
        />
        {/* <Divider className={classes.drawerDivider} /> */}
        <DrawerListItem
          key={1}
          title="Calendar"
          listIndex={1}
          onClick={handleDrawerItemClick}
          selectedIndex={drawerIndex}
          icon={<CalendarTodayOutlinedIcon />}
          drawerOpen={drawerOpen}
          handlePageChange={handleCalendarClick}
        />
        {currentProject ? (
          <DrawerNestedList
            key={2}
            title="Email"
            listIndex={2}
            handleDrawer={handleDrawerItemClick}
            handleNestedLists={toggleEmailMenu}
            selected={drawerIndex}
            icon={<EmailOutlined />}
            drawerOpen={drawerOpen}
            menuOpen={emailMenuOpen}
            subtitles={emailMenuItems}
          />
        ) : (
          <DrawerListItem
            key={2}
            title="My Notifications"
            listIndex={2}
            onClick={handleDrawerItemClick}
            selectedIndex={drawerIndex}
            icon={<EmailOutlined />}
            drawerOpen={drawerOpen}
            handlePageChange={handleMyNotificationsClick}
          />
        )}
        {currentProject && (
          <>
            <DrawerNestedList
              key={3}
              title="Design"
              listIndex={3}
              handleDrawer={handleDrawerItemClick}
              handleNestedLists={toggleDesignMenu}
              selected={drawerIndex}
              icon={<DesignIcon />}
              drawerOpen={drawerOpen}
              menuOpen={designMenuOpen}
              subtitles={designMenuItems}
            />
            {canViewBidding(productPackage) || user.isSiteAdmin ? (
              <DrawerNestedList
                key={4}
                title="Bidding"
                listIndex={4}
                handleDrawer={handleDrawerItemClick}
                handleNestedLists={toggleBiddingMenu}
                selected={drawerIndex}
                icon={<BiddingIcon />}
                drawerOpen={drawerOpen}
                menuOpen={biddingMenuOpen}
                subtitles={biddingMenuItems}
                disabled={!currentProject}
                initiateSelectedItem={initiateSelectedIndex}
              />
            ) : null}
            {canViewConstructionAndCloseout(productPackage) || user.isSiteAdmin ? (
              <div>
                <DrawerNestedList
                  key={5}
                  title="Construction"
                  listIndex={5}
                  handleDrawer={handleDrawerItemClick}
                  handleNestedLists={toggleConstructionMenu}
                  selected={drawerIndex}
                  icon={<ConstructionIcon />}
                  drawerOpen={drawerOpen}
                  menuOpen={constructionMenuOpen}
                  subtitles={constructionMenuItems}
                  disabled={!currentProject}
                  initiateSelectedItem={initiateSelectedIndex}
                />
                <DrawerNestedList
                  key={6}
                  title="Close Out"
                  listIndex={6}
                  handleDrawer={handleDrawerItemClick}
                  handleNestedLists={toggleCloseOutMenu}
                  selected={drawerIndex}
                  icon={<CloseOutIcon />}
                  drawerOpen={drawerOpen}
                  menuOpen={closeOutMenuOpen}
                  subtitles={closeOutMenuItems}
                  disabled={!currentProject}
                  initiateSelectedItem={initiateSelectedIndex}
                />
              </div>
            ) : null}
          </>
        )}
      </List>
      {drawerOpen && (
        <Button onClick={handleSignOut} className={classes.exitButton}>
          <ExitToApp style={{ marginRight: '16px', transform: 'scaleX(-1)', color: '#73A3FF' }} />
          Sign Out
        </Button>
      )}
      <Hidden smUp>
        {drawerOpen && (
          <div
            style={{
              position: 'fixed',
              width: `calc(100% - ${PRIMARY_NAVIGATION_WIDTH_OPEN}px)`,
              height: '100%',
              top: 0,
              right: 0,
            }}
            onClick={toggleDrawer}
          />
        )}
      </Hidden>
    </>
  );

  return (
    <>
      {!isPublicPage() && !isPdfViewer() && (
        <div
          className={clsx(
            'drawer',
            location.pathname === '/main/login' ||
              location.pathname === '/main/register' ||
              location.pathname === '/main/forgot-password-confirm' ||
              location.pathname === '/main/change-email-confirm' ||
              location.pathname === '/main/manage-reminders'
              ? classes.hidden
              : '',
          )}
        >
          <Hidden smUp>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={toggleDrawer}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap>
                  Centerline
                </Typography>
              </Toolbar>
            </AppBar>
          </Hidden>
          {/* Mobile drawer */}
          <Hidden smUp implementation="js">
            <Drawer
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={drawerOpen}
              onClose={toggleDrawer}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: drawerOpen,
                  [classes.drawerClose]: !drawerOpen,
                }),
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>

          {/* Desktop drawer */}
          <Hidden xsDown implementation="js">
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: drawerOpen,
                  [classes.drawerClose]: !drawerOpen,
                }),
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </div>
      )}
    </>
  );
}
