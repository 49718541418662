import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Add, Search } from '@material-ui/icons';
import { Dictionary } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularLoader from '../loader/CircularLoader';
import DocumentList from './DocumentList';
import { addSpacing, removeSpacing } from '../document-index/DocumentIndexUtils';
import { getDocumentsState, getDocumentsType } from '../../features/documents/selectors';
import { getDisableProcoreIntegrationFeatures } from '../../features/project/selectors';
import { getDocumentsLoadingState } from '../../features/loading/selectors';
import Hidden from '@material-ui/core/Hidden';
import { fileIsPdf, generateUniqueId } from '../../scripts/utils';
import Typography from '@material-ui/core/Typography';
import { DocumentTemplateType, SecurityPermissionLevel } from '../../api-client/autogenerated';
import Button from '@material-ui/core/Button';
import { setSelectedDivision, setSelectedSection } from '../../features/documents/actions';
import { getDocumentTypePermission } from '../../scripts/store-utils';
import AddDocumentDialog from '../dialogs/AddDocumentDialog';
import { TooltipIfDisabledComponent } from '../custom-components/CustomButtons';
import clsx from 'clsx';

export const divisionToDisplayString: Dictionary<string> = {
  '00': 'Procurement and Contracting Requirements',
  '01': 'General Requirements',
  '02': 'Existing Conditions',
  '03': 'Concrete',
  '04': 'Masonry',
  '05': 'Metals',
  '06': 'Wood, Plastics, and Composites',
  '07': 'Thermal and Moisture Protection',
  '08': 'Openings',
  '09': 'Finishes',
  '10': 'Specialties',
  '11': 'Equipment',
  '12': 'Furnishings',
  '13': 'Special Construction',
  '14': 'Conveying Equipment',
  '21': 'Fire Suppression',
  '22': 'Plumbing',
  '23': 'Heating, Ventilating, and Air Conditioning (HVAC)',
  '25': 'Integrated Automation',
  '26': 'Electrical',
  '27': 'Communications',
  '28': 'Electronic Safety and Security',
  '31': 'Earthwork',
  '32': 'Exterior Improvements',
  '33': 'Utilities',
  '34': 'Transportation',
  '35': 'Waterway and Marine Construction',
  '40': 'Process Interconnections',
  '41': 'Material Processing and Handling Equipment',
  '42': 'Process Heating, Cooling, and Drying Equipment',
  '43': 'Process Gas and Liquid Handling, Purification and Storage Equipment',
  '44': 'Pollution and Waste Control Equipment',
  '45': 'Industry-Specific Manufacturing Equipment',
  '46': 'Water and Wastewater Equipment',
  '48': 'Electrical Power Generation',
};

const getDisplayDivision = (division: string) => {
  if (division in divisionToDisplayString) {
    return `${division} - ${divisionToDisplayString[division]}`;
  }
  return `${division} - Unknown`;
};

type Props = {
  isDocumentDetailsPage?: boolean;
  isUploadingSubmittal?: boolean;
  setIsUploadingSubmittal?: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  setVisible: Function;
  setIndexDrawerOpen?: (input: boolean) => void;
  indexDrawerOpen: boolean;
};

export const SECONDARY_NAVIGATION_WIDTH = 238;

const useStyles = (props: Props) =>
  makeStyles((theme: Theme) => ({
    formcontrolRoot: {
      width: '86%',
      marginTop: 16,
    },
    textfield: {
      height: '32px',

      /* Gray / Gray 50 */
      background: '#F9F9F9',

      /* Gray / Gray 400 Brand dark */
      border: '1px solid #949494',
      'border-radius': '5px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px 0px 16px',
      background: '#001A4D',
      'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.1)',
      width: SECONDARY_NAVIGATION_WIDTH,
      flexShrink: 0,
      minHeight: '100vh',
      [theme.breakpoints.down(1920)]: {
        display: props.isDocumentDetailsPage ? 'none' : 'inherit',
        flexDirection: 'column',
      },
    },
    icon: {
      fill: '#B2B1B2',
      marginLeft: '4px',
    },
    listHeaderText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
      color: '#A6C4FF',
    },
    arrow: {
      color: '#A6C4FF',
      paddingLeft: '4px',
      marginLeft: '4px',
    },
    drawerArrow: {
      color: '#A6C4FF',
      textAlign: 'right',
    },
    drawerOpen: {
      overflowX: 'hidden',
      width: 238,
      alignItems: 'center',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      background: '#001A4D',
      'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.1)',
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      background: '#001A4D',
      'box-shadow': '0px 0px 10px rgba(0, 0, 0, 0.1)',
    },
  }));

export default function DocumentDisplay(props: Props) {
  const classes = useStyles(props)();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    isDocumentDetailsPage,
    isUploadingSubmittal,
    setIsUploadingSubmittal,
    visible,
    setVisible,
    setIndexDrawerOpen,
    indexDrawerOpen,
  } = props;
  const disableProcoreIntegrationFeatures = useSelector(getDisableProcoreIntegrationFeatures);
  const documents = useSelector(getDocumentsState);
  const docType = useSelector(getDocumentsType);
  const isLoading = useSelector(getDocumentsLoadingState);
  //const [visible, setVisible] = useState(false);
  const [divisions, setDivisions] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [sections, setSections] = useState<
    { id: string; number: string; title?: string; fileId?: string }[]
  >([]);
  const [addDocumentDialogOpen, setAddDocumentDialogOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const showSubmittalUpload =
    (docType === DocumentTemplateType.Submittals ||
      docType === DocumentTemplateType.CloseoutSubmittals) &&
    !isDocumentDetailsPage;

  const toggleDrawer = () => {
    setVisible(!visible);
  };

  const openDrawerIcon =
    theme.direction === 'rtl' ? (
      <ArrowBackIcon className={classes.drawerArrow} />
    ) : (
      <ArrowForwardIcon className={classes.drawerArrow} />
    );
  const closeDrawerIcon =
    theme.direction === 'rtl' ? (
      <ArrowForwardIcon className={classes.drawerArrow} />
    ) : (
      <ArrowBackIcon className={classes.drawerArrow} />
    );

  useEffect(() => {
    if (documents.length > 0) {
      // Create set from divisions and sections to remove duplicates; convert back to an array to sort
      const newSections: { id: string; number: string; title?: string; fileId?: string }[] = [];
      documents.forEach((doc) => {
        const submittalNumber = removeSpacing(doc.submittalSection);
        if (!newSections.map((section) => section.number).includes(submittalNumber)) {
          newSections.push({
            id: doc.id,
            number: submittalNumber,
            title: doc.submittalSectionDescription ?? undefined,
            fileId:
              doc.submittalSectionFile && fileIsPdf(doc.submittalSectionFile)
                ? doc.submittalSectionFile.id
                : undefined,
          });
        }
      });
      setSections(
        newSections.sort((a, b) => {
          return a.number < b.number ? -1 : 1;
        }),
      );
      setDivisions(
        Array.from(new Set<string>(documents.map((doc) => doc.submittalDivision!))).sort(),
      );
    } else {
      setDivisions([]);
      setSections([]);
    }
  }, [documents]);

  const getSectionNumberWithDivision = (section: any, division: string) => {
    if (section) {
      if (section.number) {
        return section.number.startsWith(division);
      }
    }
    return false;
  };

  const buildSections = (): JSX.Element[] => {
    return divisions
      .filter((division) => {
        if (!division) return false;
        if (searchInput.length > 0) {
          const searchInputLowercase = searchInput.toLowerCase();

          if (division.toLowerCase().includes(searchInputLowercase)) return true;
          if (getDisplayDivision(division).toLowerCase().includes(searchInputLowercase))
            return true;

          // search the subsections of each division and display the whole division if ANY subsection matches the search
          let sectionCheck = false;

          // unfortunately it seems necessary to iterate over EVERY section for each division,
          // to grab only the sections that apply to that division
          sections.forEach((section) => {
            if (getSectionNumberWithDivision(section, division)) {
              // now that we know this section applies to this division,
              // check its number and title against the search input

              // sectionString is how the section appears in the UI, its what the user would be searching against
              const sectionString = `${addSpacing(section.number)} ${section.title}`.toLowerCase();
              if (sectionString.includes(searchInputLowercase)) sectionCheck = true;
            }
          });

          return sectionCheck;
        }
        return true;
      })
      .map((division) => {
        return (
          <DocumentList
            key={generateUniqueId()}
            division={getDisplayDivision(division)}
            sections={
              sections
                ? sections.filter((section) => {
                    return getSectionNumberWithDivision(section, division);
                  })
                : []
            }
          />
        );
      });
  };

  const getTitle = () => {
    if (
      docType !== DocumentTemplateType.Submittals &&
      docType !== DocumentTemplateType.CloseoutSubmittals &&
      docType !== DocumentTemplateType.Specifications
    )
      return null;
    return (
      <Typography
        className={classes.listHeaderText}
        style={{
          fontSize: docType === DocumentTemplateType.Specifications ? 17 : 22,
          color: '#F9F9F9',
          marginTop: isMobile ? 72 : 16,
          letterSpacing: '0.85px',
        }}
      >
        {docType === DocumentTemplateType.Specifications ? 'Specifications' : 'Submittal'} Index
      </Typography>
    );
  };

  const getSubmittalUploadButton = () => {
    if (showSubmittalUpload) {
      if (isUploadingSubmittal)
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (setIsUploadingSubmittal) {
                dispatch(setSelectedDivision(null));
                dispatch(setSelectedSection(null));
                setIsUploadingSubmittal(false);
              }
            }}
            style={{ width: '86%', marginTop: 16, height: 32 }}
          >
            Go Back
          </Button>
        );
      const permission = getDocumentTypePermission();
      if (permission && permission >= SecurityPermissionLevel.NUMBER_2) {
        if (permission >= SecurityPermissionLevel.NUMBER_3)
          return (
            <TooltipIfDisabledComponent
              title={'This is disabled for projects synced with Procore'}
              disabled={disableProcoreIntegrationFeatures}
            >
              <Button
                variant="contained"
                color="secondary"
                disabled={disableProcoreIntegrationFeatures}
                onClick={() => setAddDocumentDialogOpen(true)}
                style={{ width: '86%', marginTop: 16, height: 32, whiteSpace: 'nowrap' }}
              >
                <Add style={{ paddingRight: 2 }} />
                Create Placeholder
              </Button>
            </TooltipIfDisabledComponent>
          );

        return (
          <TooltipIfDisabledComponent
            title={'This is disabled for projects synced with Procore'}
            disabled={disableProcoreIntegrationFeatures}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={disableProcoreIntegrationFeatures}
              onClick={() => {
                if (setIsUploadingSubmittal) {
                  dispatch(setSelectedDivision(null));
                  dispatch(setSelectedSection(null));
                  setIsUploadingSubmittal(true);
                } else {
                  setAddDocumentDialogOpen(true);
                }
              }}
              style={{ width: '86%', marginTop: 16, height: 32, whiteSpace: 'nowrap' }}
            >
              <Add style={{ paddingRight: 2 }} />
              Upload Submittal
            </Button>
          </TooltipIfDisabledComponent>
        );
      }
    }
    return null;
  };

  const contents = (
    <>
      {!isMobile && (
        <IconButton
          disabled={isUploadingSubmittal}
          onClick={toggleDrawer}
          style={{ alignSelf: 'flex-end', marginTop: 8 }}
        >
          {visible ? closeDrawerIcon : openDrawerIcon}
        </IconButton>
      )}
      {getTitle()}
      {getSubmittalUploadButton()}
      <FormControl
        variant="outlined"
        classes={{
          root: classes.formcontrolRoot,
        }}
      >
        <TextField
          className={classes.textfield}
          placeholder="Search..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.currentTarget.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search
                  fontSize="small"
                  classes={{
                    root: classes.icon,
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 48,
          }}
        >
          <CircularLoader />
        </div>
      ) : (
        <List style={{ width: '100%' }}>{buildSections()}</List>
      )}
      <AddDocumentDialog
        dialogOpen={addDocumentDialogOpen}
        handleClose={() => setAddDocumentDialogOpen(false)}
      />
      {addDocumentDialogOpen && (
        <div
          style={{
            position: 'absolute',
            height: document.body.offsetHeight,
            width: document.body.offsetWidth,
            left: SECONDARY_NAVIGATION_WIDTH,
            backgroundColor: 'rgba(70,69,70,0.35)',
            zIndex: 1200,
          }}
        />
      )}
    </>
  );

  return isMobile ? (
    <Drawer
      anchor="left"
      open={indexDrawerOpen}
      variant="temporary"
      onClose={() => {
        if (setIndexDrawerOpen !== undefined) setIndexDrawerOpen(false);
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: indexDrawerOpen,
          [classes.drawerClose]: !indexDrawerOpen,
        }),
      }}
    >
      {contents}
    </Drawer>
  ) : visible ? (
    <>
      {/* desktop */}
      <Hidden xsDown implementation="js">
        <Box className={classes.container} style={{ position: 'relative' }}>
          {contents}
        </Box>
      </Hidden>
    </>
  ) : (
    <>
      {/* desktop */}
      <Hidden xsDown implementation="js">
        <Box className={classes.container} style={{ width: '60px' }}>
          <IconButton style={{ marginTop: 8 }} onClick={toggleDrawer}>
            {visible ? closeDrawerIcon : openDrawerIcon}
          </IconButton>
        </Box>
      </Hidden>
    </>
  );
}
