import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import { Add, ArrowBack } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { IProjectUser } from '../../api-client/autogenerated';
import { ISecurityGroup, IUserSimplified } from '../../api-client/autogenerated';
import { GreenCheck } from '../custom-components/CustomCheckboxes';
import { UserActionType } from '../form-pages/AddOrManageProjectPage';
import { getComparator, stableSort } from '../document-index/DocumentIndexUtils';

type AddPreviousUsersTableProps = {
  handleBack: () => void;
  createProjectUsers: (projectUser: IProjectUser[], action?: UserActionType) => void;
  //used to filter out users in the table that are already in the project
  users: IProjectUser[] | undefined;
  simplifiedUsers: IUserSimplified[] | undefined;
  securityGroups: ISecurityGroup[];
  handleUsersAdded: () => void;
};

const headCells = [
  { id: 'company', label: 'Company Name' },
  { id: 'name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'securityGroupId', label: 'Security Group' },
];

interface PreviousUserRow {
  id: string;
  name: string;
  email: string;
  company: string;
  securityGroupId?: string;
  isChecked: boolean;
  user: IUserSimplified;
}

export default function AddPreviousUsersTable(props: AddPreviousUsersTableProps) {
  const {
    handleBack,
    createProjectUsers,
    users,
    simplifiedUsers,
    securityGroups,
    handleUsersAdded,
  } = props;

  const [rows, setRows] = useState<PreviousUserRow[]>([]);
  const [orderBy, setOrderBy] = useState('company');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  function handleClick(id: string) {
    const index = rows.findIndex((row) => row.id === id);
    setRows((prevState: string | any[]) => [
      ...(prevState?.slice(0, index) || []),
      {
        ...prevState[index],
        isChecked: prevState ? !prevState[index].isChecked : false,
      },
      ...(prevState?.slice(index + 1) || []),
    ]);
  }

  useEffect(() => {
    if (simplifiedUsers) {
      const previousUsers = simplifiedUsers.filter((su) => !users?.some((u) => su.id === u.userId));
      setRows(
        previousUsers.map((user) => ({
          id: user.id,
          name: user.name,
          email: user.email,
          company: user.company?.name || '-',
          securityGroupId: user.securityGroupId,
          isChecked: false,
          user,
        })),
      );
    }
  }, [simplifiedUsers]);

  const handleAddUsersPressed = () => {
    const selectedRows = rows.filter((x) => x.isChecked);
    if (selectedRows.length > 0) {
      const usersToAdd: IProjectUser[] = [];
      selectedRows.forEach((x) => {
        usersToAdd.push({
          user: x.user,
          securityGroupId: x.securityGroupId,
          securityGroup: securityGroups.find((y) => y.id === x.securityGroupId),
        } as IProjectUser);
      });
      createProjectUsers(usersToAdd);
      handleUsersAdded();
    }
  };

  const updateSelectedSecurityGroup = (newSecurityGroupId: string, index: number) => {
    if (newSecurityGroupId !== rows[index].securityGroupId) {
      const rowsTemp = [...rows];
      rowsTemp[index].securityGroupId = newSecurityGroupId;
      rowsTemp[index].isChecked = true;
      setRows(rowsTemp);
    }
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<HTMLSpanElement>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let dialogContent =
    rows && rows.length > 0 ? (
      <TableContainer style={{ maxHeight: '450px' }}>
        <Table aria-label="dense">
          <TableHead>
            <TableCell padding="checkbox">
              <GreenCheck
                onChange={(e) => {
                  setRows((prevState: any[]) =>
                    prevState.map((row: any) => {
                      return { ...row, isChecked: e.target.checked };
                    }),
                  );
                }}
              />
            </TableCell>
            {headCells.map((h) => (
              <TableCell key={h.id} align="left" sortDirection={orderBy === h.id ? order : false}>
                <TableSortLabel
                  active={orderBy === h.id}
                  direction={orderBy === h.id ? order : 'asc'}
                  onClick={createSortHandler(h.id)}
                >
                  {h.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {stableSort<any>(rows, getComparator(order, orderBy)).map((row, index) => {
              return (
                <TableRow hover key={row.id} role="checkbox">
                  <TableCell padding="checkbox">
                    <GreenCheck
                      onClick={() => handleClick(row.id)}
                      checked={row.isChecked}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="left">{row.company}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">
                    <FormControl
                      variant="outlined"
                      size="small"
                      style={{ marginTop: 4, marginBottom: 4 }}
                    >
                      <Select
                        id="security-group-dropdown"
                        value={row.securityGroupId}
                        style={{ width: 250 }}
                        onChange={(event) =>
                          updateSelectedSecurityGroup(event.target.value as string, index)
                        }
                      >
                        {securityGroups?.map((group) => {
                          return (
                            <MenuItem key={group.id} value={group.id!}>
                              {group.name!}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <Typography style={{ paddingLeft: 24, paddingTop: 16, paddingBottom: 32 }}>
        No available users from past projects.
      </Typography>
    );

  return (
    <div>
      {!simplifiedUsers ? (
        <div
          style={{ width: '100%', margin: '20px 0px', display: 'flex', justifyContent: 'center' }}
        >
          <CircularProgress size="40px" thickness={3} color="primary" />
        </div>
      ) : (
        dialogContent
      )}
      <div
        style={{
          width: '100%',
          paddingTop: 16,
          paddingBottom: 8,
          paddingLeft: 16,
          paddingRight: 16,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button variant="outlined" startIcon={<ArrowBack />} onClick={handleBack}>
          Back
        </Button>
        <Button
          onClick={handleAddUsersPressed}
          variant="contained"
          color="primary"
          disabled={!rows?.find((x) => x.isChecked)}
          style={{
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Add style={{ paddingRight: 2 }} />
          Add Users
        </Button>
      </div>
    </div>
  );
}
