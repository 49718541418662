import React, { useEffect, useState } from 'react';
import { Button, Card, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserState } from '../../../features/user/selectors';
import logoSvg from '../../../images/wordmark-blue.svg';
import CircularLoader from '../../loader/CircularLoader';
import { confirmEmailChange } from '../../../models/api/users';
import { fetchUser } from '../../../features/user/actions';
import { IUser } from '../../../api-client/autogenerated';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: '160px',
      paddingTop: '2px',
      transition: 'filter .2s',
      '&:hover': {
        filter: 'opacity(85%)',
      },
    },
    card: {
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.up('sm')]: { width: '60%', height: '60vh' },
    },
  }),
);

export default function ChangeEmailPage() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector(getUserState);

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const changeEmailToken = params.get('change_email_token');

  const [isLoading, setIsLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');
  const [modifiedUser, setModifiedUser] = useState<IUser>();

  const isBiddingUser = !modifiedUser?.companyId;

  useEffect(() => {
    if (changeEmailToken) {
      confirmEmailChange(changeEmailToken)
        .then((u) => {
          setStatusMessage('Your email was successfully changed!');
          setModifiedUser(u);
        })
        .catch(() => setStatusMessage('Something went wrong while changing your email.'))
        .finally(() => {
          setIsLoading(false);
          window.history.replaceState({}, '', window.location.pathname);
        });
    } else {
      setIsLoading(false);
      setStatusMessage('Something went wrong while changing your email.');
    }
  }, [changeEmailToken]);

  const redirect = () => {
    if (user.id) {
      dispatch(fetchUser());
      history.push(isBiddingUser ? '/bidding/account' : '/main/settings');
    } else {
      history.push(isBiddingUser ? '/bidding/login' : '/main/login', {
        email: modifiedUser?.email,
      });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
      <Card className={classes.card}>
        <img
          src={logoSvg}
          alt="centerline-logo"
          className={classes.logo}
          style={{ position: 'relative', left: 16, top: 12 }}
        />
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10vh' }}>
          {isLoading ? (
            <CircularLoader />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography style={{ fontSize: '16px', textAlign: 'center' }}>
                {statusMessage}
              </Typography>
              <Button
                onClick={redirect}
                color="primary"
                variant="contained"
                style={{ marginTop: '64px' }}
              >
                Return to Centerline
              </Button>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
