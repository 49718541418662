import React, { useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import getCustomTheme from './custom-components/customTheme';
import NavDrawer from './drawer/NavDrawer';
import FilesystemPage from './design/FilesystemPage';
import { combineClasses } from '../scripts/utils';
import Dashboard from './dashboard/Dashboard';
import Calendar from './calendar/Calendar';
import DocumentDetailsPage from './document-details/DocumentDetailsPage';
import DocumentLogPage from './document-log-table-page/DocumentLogPage';
import SettingsPage from './form-pages/SettingsPage';
import MainPortalRegister from './MainPortalRegister';
import MainPortalLogin from './MainPortalLogin';
import PrivateRoute from './PrivateRoute';
import BidManagementPage from './form-pages/BidManagementPage';
import AddOrManageProjectPage from './form-pages/AddOrManageProjectPage';
import AddFieldReportsPage from './form-pages/AddFieldReportsPage';
import ConstructionSetupPage from './form-pages/ConstructionSetupPage';
import ProjectDirectoryPage from './project-directory-page/ProjectDirectoryPage';
import SecurityGroupsPage from './form-pages/security-groups/SecurityGroupsPage';
import OAuthCallback from '../oauth-callback/OAuthCallback';
import { OauthProviderType } from '../api-client/autogenerated';
import ForgotPasswordPage from './form-pages/ForgotPasswordPage';
import PDFViewer from './pdf-viewer/PDFViewer';
import SubscriberAdminDashboard from './admin-dashboard/SubscriberAdminDashboard';
import GlobalSnackbar from './GlobalSnackbar';
import GlobalNavigationBlocker from './GlobalNavigationBlocker';
import ManageUserGroupsPage from './form-pages/user-groups/ManageUserGroupsPage';
import SiteAdminDashboard from './admin-dashboard/SiteAdminDashboard';
import ManageRemindersPage from './form-pages/ManageRemindersPage';
import LockedProjectPage from './LockedProjectPage';
import DocumentAccessRestrictedPage from './DocumentAccessRestrictedPage';
import PunchListSetup from './punch-lists/PunchListSetup';
import ScrollToTop from './ScrollToTop';
import CreatePunchListPage from './punch-lists/CreatePunchListPage';
import PublishingCenter from './document-conforming-center/PublishingCenter';
import DCCDrawings from './document-conforming-center/DCCDrawings';
import DCCSpecifications from './document-conforming-center/DCCSpecifications';
import DCCSubmittals from './document-conforming-center/DCCSubmittals';
import PunchListPrintView from './printing/PunchListPrintView';
import DocumentPrintView from './printing/DocumentPrintView';
import CorrespondenceLog, { EmailLogType } from './email/CorrespondenceLog';
import ChangeEmailPage from './form-pages/settings/ChangeEmailPage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '@media print': {
      display: 'block',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh',
    background: '#FAFAFA',
  },
  contentNoPadding: {
    flexGrow: 1,
    minHeight: '100vh',
    background: '#FAFAFA',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    height: '100%', // align bottom of grid items
  },
}));

export default function MainPortal() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { path } = useRouteMatch();
  const classes = useStyles();
  const customTheme = getCustomTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [indexDrawerOpen, setIndexDrawerOpen] = useState(false);

  return (
    <ThemeProvider theme={customTheme}>
      <div className={combineClasses('main', classes.root)}>
        <CssBaseline />
        <NavDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        <ScrollToTop />
        <Switch>
          <PrivateRoute path={`${path}/projects/:projectId/locked-project`}>
            <LockedProjectPage />
          </PrivateRoute>

          <PrivateRoute path={`${path}/projects/locked-project`}>
            <LockedProjectPage />
          </PrivateRoute>
          <Route path={`${path}/login`}>
            <main className={classes.contentNoPadding}>
              <MainPortalLogin />
            </main>
          </Route>
          <PrivateRoute path={`${path}/subscriber-admin`}>
            <main className={classes.content}>
              <SubscriberAdminDashboard />
            </main>
          </PrivateRoute>
          <PrivateRoute path={`${path}/site-admin`}>
            <main className={classes.content}>
              <SiteAdminDashboard />
            </main>
          </PrivateRoute>
          <Route path={`${path}/register`}>
            <main className={classes.content}>
              <MainPortalRegister />
            </main>
          </Route>
          <Route path={`${path}/forgot-password-confirm`}>
            <main className={classes.contentNoPadding}>
              <ForgotPasswordPage />
            </main>
          </Route>
          <Route path={`${path}/change-email-confirm`}>
            <main className={classes.contentNoPadding}>
              <ChangeEmailPage />
            </main>
          </Route>
          <Route path={`${path}/public/manage-reminders/:key?`}>
            <main className={classes.content}>
              <ManageRemindersPage />
            </main>
          </Route>
          <Route path={`${path}/public/documents/:key`}>
            <DocumentDetailsPage indexDrawerOpen={indexDrawerOpen} />
          </Route>
          <Route path={`${path}/public/projects/:projectId/design-file-share/:designFileShareId`}>
            <FilesystemPage />
          </Route>
          <PrivateRoute path={`${path}/projects/:projectId/documents/access-restricted`}>
            <DocumentAccessRestrictedPage />
          </PrivateRoute>
          <PrivateRoute exact path={`${path}/projects/:projectId/design`}>
            <main className={classes.content}>
              <div className={classes.root}>
                <FilesystemPage />
              </div>
            </main>
          </PrivateRoute>
          <PrivateRoute path={`${path}/projects/:projectId/design/BIM`}>
            <main className={classes.content}>
              <div className={classes.root}>
                <FilesystemPage BIM />
              </div>
            </main>
          </PrivateRoute>
          <PrivateRoute path="/main/bim360-callback">
            <OAuthCallback provider={OauthProviderType.Bim} redirectTo={`${path}`} />
          </PrivateRoute>
          <PrivateRoute path="/main/procore-callback">
            <OAuthCallback provider={OauthProviderType.Procore} redirectTo={`${path}`} />
          </PrivateRoute>
          <PrivateRoute exact path={[`${path}`, `${path}/projects/:projectId`]}>
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path={[`${path}/projects/:projectId/calendar`, `${path}/calendar`]}>
            <Calendar />
          </PrivateRoute>
          <PrivateRoute path={[`${path}/projects/:projectId/correspondence-log`]}>
            <CorrespondenceLog type={EmailLogType.CORRESPONDENCE_LOG} />
          </PrivateRoute>
          <PrivateRoute path={[`${path}/my-notifications`]}>
            <CorrespondenceLog type={EmailLogType.MY_NOTIFICATIONS} />
          </PrivateRoute>
          <PrivateRoute path={[`${path}/projects/:projectId/my-notifications`]}>
            <CorrespondenceLog type={EmailLogType.MY_NOTIFICATIONS} />
          </PrivateRoute>

          <PrivateRoute exact path={`${path}/project`}>
            <AddOrManageProjectPage />
          </PrivateRoute>

          <PrivateRoute exact path={`${path}/projects/:projectId/manage`}>
            <AddOrManageProjectPage edit />
          </PrivateRoute>

          <PrivateRoute exact path={`${path}/security-groups`}>
            <SecurityGroupsPage />
          </PrivateRoute>

          <PrivateRoute exact path={`${path}/projects/:projectId/user-groups`}>
            <ManageUserGroupsPage />
          </PrivateRoute>

          <PrivateRoute path={`${path}/projects/:projectId/bid-mgmt`}>
            <BidManagementPage />
          </PrivateRoute>

          <PrivateRoute path={`${path}/projects/:projectId/const-mgmt`}>
            <ConstructionSetupPage />
          </PrivateRoute>

          <PrivateRoute path={`${path}/projects/:projectId/pub-center/conform-drawings`}>
            <DCCDrawings />
          </PrivateRoute>

          <PrivateRoute path={`${path}/projects/:projectId/pub-center/conform-specifications`}>
            <DCCSpecifications />
          </PrivateRoute>

          <PrivateRoute path={`${path}/projects/:projectId/pub-center/conform-submittals`}>
            <DCCSubmittals />
          </PrivateRoute>

          <PrivateRoute path={`${path}/projects/:projectId/pub-center`}>
            <PublishingCenter />
          </PrivateRoute>

          <PrivateRoute exact path={`${path}/projects/:projectId/directory`}>
            <ProjectDirectoryPage />
          </PrivateRoute>

          <PrivateRoute path={`${path}/settings`}>
            <SettingsPage />
          </PrivateRoute>

          {/*<PrivateRoute path={`${path}/setcompany`}>*/}
          {/*  <SelectCompanyPage />*/}
          {/*</PrivateRoute>*/}

          <PrivateRoute path={`${path}/projects/:projectId/documents/punch-list/setup`}>
            <PunchListSetup />
          </PrivateRoute>

          <PrivateRoute exact path={`${path}/projects/:projectId/documents/:type`}>
            <DocumentLogPage
              drawerOpen={drawerOpen}
              setIndexDrawerOpen={setIndexDrawerOpen}
              indexDrawerOpen={indexDrawerOpen}
            />
          </PrivateRoute>

          <PrivateRoute exact path={`${path}/projects/:projectId/documents/field-reports/create`}>
            <AddFieldReportsPage />
          </PrivateRoute>

          <PrivateRoute exact path={`${path}/projects/:projectId/documents/punch-list/create`}>
            <CreatePunchListPage mode="CREATE" />
          </PrivateRoute>

          <PrivateRoute exact path={`${path}/projects/:projectId/documents/punch-list/print-all`}>
            <PunchListPrintView mode="ALL" />
          </PrivateRoute>

          <PrivateRoute
            exact
            path={`${path}/projects/:projectId/documents/field-reports/:documentId/edit`}
          >
            <AddFieldReportsPage />
          </PrivateRoute>

          <PrivateRoute
            exact
            path={`${path}/projects/:projectId/documents/punch-list/:documentId/edit`}
          >
            <CreatePunchListPage mode="EDIT_ITEM" />
          </PrivateRoute>

          <PrivateRoute
            exact
            path={`${path}/projects/:projectId/documents/punch-list/:locationId/print-location`}
          >
            <PunchListPrintView mode="LOCATION" />
          </PrivateRoute>

          <PrivateRoute
            exact
            path={`${path}/projects/:projectId/documents/punch-list/:documentId/print`}
          >
            <PunchListPrintView mode="ITEM" />
          </PrivateRoute>

          <PrivateRoute
            exact
            path={`${path}/projects/:projectId/documents/punch-list/:locationId/edit-location`}
          >
            <CreatePunchListPage mode="EDIT_LOCATION" />
          </PrivateRoute>

          <PrivateRoute
            exact
            path={[
              `${path}/projects/:projectId/documents/:type/:documentId`,
              `${path}/projects/:projectId/documents/:type/:documentId/comments/:commentId?`,
            ]}
          >
            <DocumentDetailsPage indexDrawerOpen={indexDrawerOpen} />
          </PrivateRoute>
          <PrivateRoute
            exact
            path={`${path}/projects/:projectId/documents/:type/:documentId/print`}
          >
            <DocumentPrintView />
          </PrivateRoute>
          <Route path={[`${path}/pdf/:fileId`, `${path}/public/pdf/:fileId`, `${path}/pdf/design`]}>
            <PDFViewer />
          </Route>
        </Switch>
        <GlobalSnackbar />
        <GlobalNavigationBlocker />
      </div>
    </ThemeProvider>
  );
}
