import React from 'react';
import List from '@material-ui/core/List';
import { Collapse, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import { ascendingComparator } from '../../document-index/DocumentIndexUtils';
import { ICompany, IUser } from '../../../api-client/autogenerated';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { isPublicPage, sortLastNamesDesc } from '../../../scripts/utils';

const sampleUserData: IUser[] = [
  {
    id: '00000000-0000-0000-0000-000000000000',
    lastUpdatedByUserId: '00000000-0000-0000-0000-000000000000',
    name: 'Scott Johnson',
    email: 'pie@centerline.com',
    company: {
      id: '00000000-0000-0000-0000-000000000000',
      lastUpdatedByUserId: '00000000-0000-0000-0000-000000000000',
      name: 'Admin Company',
    } as ICompany,
  },
  {
    id: '00000000-0000-0000-0000-000000000000',
    lastUpdatedByUserId: '00000000-0000-0000-0000-000000000000',
    name: 'Alexander Adams',
    email: 'pie@centerline.com',
    company: {
      id: '00000000-0000-0000-0000-000000000000',
      lastUpdatedByUserId: '00000000-0000-0000-0000-000000000000',
      name: 'Admin Company',
    } as ICompany,
  },
  {
    id: '00000000-0000-0000-0000-000000000000',
    lastUpdatedByUserId: '00000000-0000-0000-0000-000000000000',
    name: 'Daniel Atkinson',
    email: 'pie@centerline.com',
    company: {
      id: '00000000-0000-0000-0000-000000000000',
      lastUpdatedByUserId: '00000000-0000-0000-0000-000000000000',
      name: 'Dev Company',
    } as ICompany,
  },
  {
    id: '00000000-0000-0000-0000-000000000000',
    lastUpdatedByUserId: '00000000-0000-0000-0000-000000000000',
    name: 'TJ Mathews',
    email: 'pie@centerline.com',
    company: {
      id: '00000000-0000-0000-0000-000000000000',
      lastUpdatedByUserId: '00000000-0000-0000-0000-000000000000',
      name: 'Dev Company',
    } as ICompany,
  },
  {
    id: '00000000-0000-0000-0000-000000000000',
    lastUpdatedByUserId: '00000000-0000-0000-0000-000000000000',
    name: 'William Woodfin',
    email: 'pie@centerline.com',
    company: {
      id: '00000000-0000-0000-0000-000000000000',
      lastUpdatedByUserId: '00000000-0000-0000-0000-000000000000',
      name: 'Dev Company',
    } as ICompany,
  },
];

type Props = {
  open: boolean;
  users?: IUser[];
  nonCenterlineUsers?: string[];
  limitHeight?: boolean;
  whiteText?: boolean;
  isDocumentDetail?: boolean;
  handleDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, userId: string) => void;
};

const useStyles = makeStyles<undefined, Props>({
  companyText: {
    fontSize: '20px',
    lineHeight: '32px',
    textTransform: 'none',
    color: (props) => (props.whiteText ? 'white' : 'gray'),
  },
  userText: {
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'none',
    color: (props) => (props.whiteText ? 'white' : 'gray'),
  },
});

export default function DistributionList(props: Props) {
  const classes = useStyles(props);
  const { open, users, nonCenterlineUsers, limitHeight, isDocumentDetail, handleDelete } = props;

  const getUserRows = () => {
    const rowData: {
      company: string;
      users: { id: string; name: string; email?: string }[];
    }[] = [];
    users?.forEach((user) => {
      const existingRow = rowData.find(
        (row) => row.company === (user.company?.name || 'Unknown Company'),
      );
      if (existingRow) existingRow.users.push(user);
      else rowData.push({ company: user.company?.name || 'Unknown Company', users: [user] });
    });

    if (nonCenterlineUsers?.length) {
      rowData.push({
        company: 'Non-Centerline Users',
        users: nonCenterlineUsers.map((name, index) => ({ id: index.toString(), name })),
      });
    }

    return rowData.sort((a, b) => ascendingComparator(a, b, 'company'));
  };

  return (
    <Collapse
      in={open}
      unmountOnExit
      style={limitHeight ? { maxHeight: '300px', overflowY: 'auto' } : undefined}
    >
      {getUserRows().map(({ company, users }, index, array) => (
        <List
          dense
          disablePadding
          key={company}
          style={{
            paddingLeft: 5,
            marginLeft: '26px',
            marginBottom: index === array.length - 1 && isDocumentDetail ? '12px' : 0,
          }}
        >
          <ListSubheader disableSticky disableGutters className={classes.companyText}>
            {company}
          </ListSubheader>
          {users
            .sort((a, b) => sortLastNamesDesc(a.name, b.name))
            .map((user) => (
              <ListItem key={user.id} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItemText
                  primary={user.email ? user.name + ` (${user.email})` : user.name}
                  classes={{ primary: classes.userText }}
                />
              </ListItem>
            ))}
        </List>
      ))}
      {(!users || users.length === 0) && !nonCenterlineUsers?.length && (
        <Typography className={classes.companyText} style={{ margin: '6px', marginLeft: '6px' }}>
          {isPublicPage() ? 'Sign in to view members of this group.' : 'No users'}
        </Typography>
      )}
    </Collapse>
  );
}
