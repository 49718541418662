import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { DisciplineType, ICompany } from '../../../api-client/autogenerated';
import { getUserState } from '../../../features/user/selectors';
import { patchUser } from '../../../models/api/users';
import { insertCompany } from '../../../models/api/companies';
import { getCompaniesState } from '../../../features/companies/selector';
import { addSnackbar } from '../../../features/snackbar/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    margin: {
      margin: theme.spacing(1),
      height: '70%', // make buttons same height
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
    },
    alignLeft: {
      textAlign: 'left',
    },
  }),
);

export default function SettingsAccountForm() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const companies = useSelector(getCompaniesState);

  const [name, setName] = useState<string>();
  const [selectedDisciplines, setSelectedDisciplines] = useState<DisciplineType[]>([]);
  const [licenseNumber, setLicenseNumber] = useState<string | undefined>();
  const [inputCompany, setInputCompany] = useState('');
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);

  const addNewCompany = () => {
    console.log('Attempt to add company');
    insertCompany({ name: inputCompany }).then((comp) => {
      setInputCompany(comp.name);
      setSelectedCompany(comp);
    });
  };

  useEffect(() => {
    handleFirstLoad();
  }, [user, companies]);

  const handleFirstLoad = () => {
    setName(user.name);
    // setSelectedDisciplines(user.userDisciplineList?.map((u) => u.discipline) || []);
    setLicenseNumber(user.contractorLicenseNumber || undefined);
    setSelectedCompany(user.company || companies.find(({ id }) => id === user.companyId) || null);
  };

  const handleSubmit = async () => {
    try {
      await patchUser(user.id, {
        name: name || undefined,
        // userDisciplineList: selectedDisciplines.map((d) => {
        //   return { discipline: d };
        // }),
        // contractorLicenseNumber: licenseNumber,
        // companyId: selectedCompany?.id,
      });

      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Your account was successfully updated.',
          severity: 'success',
        }),
      );
    } catch {
      dispatch(
        addSnackbar({
          id: Date.now(),
          message: 'Something went wrong while updating your account.',
          severity: 'error',
        }),
      );
    }
  };

  // TODO: Enable name field when email is attached to name everywhere in site

  return (
    <form className={classes.root} noValidate autoComplete="off" style={{ maxWidth: 500 }}>
      <Grid container spacing={2} justify="flex-start">
        <Grid item xs={11}>
          <TextField
            id="name"
            label="Name"
            type="text"
            name="name"
            autoComplete="name"
            variant="outlined"
            fullWidth
            color="primary"
            disabled
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={11}>
          <TextField
            label="Company"
            fullWidth
            color="primary"
            variant="outlined"
            disabled
            defaultValue={user.company?.name}
          />
        </Grid>
        {/*<Grid item xs={11}>*/}
        {/*  <Autocomplete*/}
        {/*    id="combo-box-demo"*/}
        {/*    fullWidth*/}
        {/*    disabled={isBiddingPortal}*/}
        {/*    options={companies}*/}
        {/*    getOptionLabel={(option: ICompany) => option.name}*/}
        {/*    inputValue={inputCompany}*/}
        {/*    onInputChange={(e, value) => setInputCompany(value)}*/}
        {/*    onChange={(e, value) => setSelectedCompany(value)}*/}
        {/*    limitTags={5}*/}
        {/*    filterOptions={(comps, state) =>*/}
        {/*      state.inputValue.length > 2*/}
        {/*        ? comps*/}
        {/*            .filter((x) => x.name.toLowerCase().includes(state.inputValue.toLowerCase()))*/}
        {/*            .slice(0, 4)*/}
        {/*        : []*/}
        {/*    }*/}
        {/*    noOptionsText={*/}
        {/*      inputCompany.length > 2 ? (*/}
        {/*        <MenuItem onMouseDown={addNewCompany}>*/}
        {/*          Add "{inputCompany}" as a new company*/}
        {/*        </MenuItem>*/}
        {/*      ) : (*/}
        {/*        'Type to see results'*/}
        {/*      )*/}
        {/*    }*/}
        {/*    autoComplete*/}
        {/*    renderInput={(params) => (*/}
        {/*      <TextField*/}
        {/*        {...params}*/}
        {/*        variant="outlined"*/}
        {/*        margin="normal"*/}
        {/*        required*/}
        {/*        fullWidth*/}
        {/*        label="Company"*/}
        {/*        autoComplete="Company"*/}
        {/*        name="email"*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    value={selectedCompany}*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={11}>*/}
        {/*  <TextField*/}
        {/*    id="license-number"*/}
        {/*    type="text"*/}
        {/*    name="license-number"*/}
        {/*    label="License Number"*/}
        {/*    value={licenseNumber}*/}
        {/*    onChange={(e) => setLicenseNumber(e.target.value)}*/}
        {/*    variant="outlined"*/}
        {/*    fullWidth*/}
        {/*  />*/}
        {/*</Grid>*/}
        {/*<Grid item xs={11}>*/}
        {/*<FormControl*/}
        {/*  variant="outlined"*/}
        {/*  fullWidth*/}
        {/*  margin="none"*/}
        {/*  style={{ marginTop: 16, maxWidth: 350 }}*/}
        {/*>*/}
        {/*  <InputLabel variant="outlined">Disciplines</InputLabel>*/}
        {/*  <Select*/}
        {/*    label="Disciplines"*/}
        {/*    style={{ textTransform: 'capitalize' }}*/}
        {/*    value={selectedDisciplines}*/}
        {/*    multiple*/}
        {/*    displayEmpty*/}
        {/*    margin="none"*/}
        {/*    MenuProps={{*/}
        {/*      // @ts-ignore*/}
        {/*      getContentAnchorEl: () => null,*/}
        {/*      autoFocus: true,*/}
        {/*    }}*/}
        {/*    onChange={(event) => setSelectedDisciplines(event.target.value as DisciplineType[])}*/}
        {/*  >*/}
        {/*    {Object.values(DisciplineType).map((discipline) => {*/}
        {/*      return (*/}
        {/*        <MenuItem*/}
        {/*          key={discipline}*/}
        {/*          style={{ textTransform: 'capitalize' }}*/}
        {/*          value={discipline}*/}
        {/*        >*/}
        {/*          {discipline.replaceAll('_', ' ')}*/}
        {/*        </MenuItem>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </Select>*/}
        {/*</FormControl>*/}
        {/*<Grid item xs={11}>*/}
        {/*  <FormControl*/}
        {/*    variant="outlined"*/}
        {/*    fullWidth*/}
        {/*    margin="none"*/}
        {/*    style={{ marginBottom: 16, maxWidth: 350 }}*/}
        {/*  >*/}
        {/*    <TextField*/}
        {/*      variant="outlined"*/}
        {/*      margin="normal"*/}
        {/*      fullWidth*/}
        {/*      id="city"*/}
        {/*      name="City"*/}
        {/*      label="City"*/}
        {/*      autoComplete="City"*/}
        {/*      value={cityName}*/}
        {/*      onChange={(event) => setCity(event.target.value)}*/}
        {/*    />*/}
        {/*  </FormControl>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={11}>*/}
        {/*  <FormControl variant="outlined" fullWidth margin="none" style={{ maxWidth: 350 }}>*/}
        {/*    <Typography variant="body1" style={{ paddingBottom: 4 }}>*/}
        {/*      State*/}
        {/*    </Typography>*/}
        {/*    <Select*/}
        {/*      value={stateLocation}*/}
        {/*      fullWidth*/}
        {/*      variant="outlined"*/}
        {/*      autoComplete="State"*/}
        {/*      style={{ marginTop: 0, marginBottom: 8 }}*/}
        {/*      onChange={(event) => {*/}
        {/*        setStateLocation(event.target.value as string);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {' '}*/}
        {/*      <MenuItem value="" disabled>*/}
        {/*        Select State*/}
        {/*      </MenuItem>*/}
        {/*      {getStateAndProvinceItems()}*/}
        {/*    </Select>*/}
        {/*  </FormControl>*/}
        {/*</Grid>*/}
        {/*</Grid>*/}
      </Grid>
      <Grid container alignItems="flex-end" justify="flex-start" spacing={2}>
        {/*<Grid item xs={11} lg={6}>*/}
        {/*  <FormControl fullWidth>*/}
        {/*    <InputLabel htmlFor="demo-customized-textbox">Phone</InputLabel>*/}
        {/*    <BootstrapInput*/}
        {/*      id="demo-customized-textbox"*/}
        {/*      value={values.textmask}*/}
        {/*      onChange={handleChange}*/}
        {/*      name="textmask"*/}
        {/*      inputComponent={TextMaskCustom as any}*/}
        {/*      fullWidth*/}
        {/*    />*/}
        {/*  </FormControl>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={11} lg={5}>*/}
        {/*  <FormControl variant="outlined" fullWidth>*/}
        {/*    <InputLabel htmlFor="outlined-age-native-simple">Phone Type</InputLabel>*/}
        {/*    <Select*/}
        {/*      value={values.phoneType}*/}
        {/*      onChange={handleChange}*/}
        {/*      label="Phone Type"*/}
        {/*      inputProps={{*/}
        {/*        name: 'phoneType',*/}
        {/*        id: 'phoneType',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <MenuItem value="mobile">Mobile</MenuItem>*/}
        {/*      <MenuItem value="home">Home</MenuItem>*/}
        {/*      <MenuItem value="work">Work</MenuItem>*/}
        {/*    </Select>*/}
        {/*  </FormControl>*/}
        {/*</Grid>*/}
      </Grid>
      {/* TODO: Uncomment this when name field is enabled */}
      {/*<Grid container>*/}
      {/*  <Grid item>*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      color="default"*/}
      {/*      size="medium"*/}
      {/*      type="reset"*/}
      {/*      disableElevation*/}
      {/*      className={classes.margin}*/}
      {/*      onClick={handleFirstLoad}*/}
      {/*    >*/}
      {/*      Cancel*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      variant="contained"*/}
      {/*      color="primary"*/}
      {/*      size="medium"*/}
      {/*      disableElevation*/}
      {/*      disabled={*/}
      {/*        !name ||*/}
      {/*        (name === user.name && (licenseNumber || '') === (user.contractorLicenseNumber || ''))*/}
      {/*      }*/}
      {/*      className={classes.margin}*/}
      {/*      onClick={handleSubmit}*/}
      {/*    >*/}
      {/*      Save*/}
      {/*    </Button>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}
    </form>
  );
}
