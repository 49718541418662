import { ICompany, IInsertionCompany, IOptionalCompany } from '../../api-client/autogenerated';
import { ApiClient, getQueryFilterString, QueryFilter } from '../../api-client/api-client';

// GET //

export const getCompanies = async (
  limit = 50,
  orderByColumn = '',
  filterByName = '',
): Promise<ICompany[]> => {
  const filter = getQueryFilterString([
    { whereColumn: 'name', whereOperator: 'ILIKE', whereValue: `%${filterByName}%` },
  ]);
  const response = await ApiClient.getAllCompanies({
    pageSize: limit,
    orderByColumn: orderByColumn,
    filterByObject: filterByName ? filter : undefined,
  });
  return response.data.results;
};

export const getCompaniesByProjectId = async (projectId: string): Promise<ICompany[]> => {
  return (await ApiClient.getCompaniesByProjectId({ projectId })).data;
};

export const getCompanyById = async (companyId: string): Promise<ICompany> => {
  return (await ApiClient.getCompanyById({ companyId })).data;
};

export const getCompanyByName = async (name: string): Promise<ICompany> => {
  const filter: QueryFilter = [{ whereColumn: 'name', whereOperator: '=', whereValue: name }];

  const { results } = (
    await ApiClient.getAllCompanies({ filterByObject: getQueryFilterString(filter) })
  ).data;
  if (results.length === 1) {
    return results[0];
  }
  return {} as ICompany;
};

export const insertCompany = async (company: IInsertionCompany): Promise<ICompany> => {
  const response = await ApiClient.insertCompany({ iInsertionCompany: company });
  return response.data;
};

export const editCompanyById = async (
  companyId: string,
  patch: IOptionalCompany,
): Promise<ICompany> => {
  return ApiClient.editCompanyById({ companyId, iOptionalCompany: patch }).then((res) => res.data);
};

// PUT - NOT IMPLEMENTED //

// export const putCompany = (companyId: string, company: ICompany): Promise<number> => {
//   return API.put(`${URL}/${companyId}`, company).then((res) => res.status);
// };
