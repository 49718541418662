import { Link as RouterLink, useLocation } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useDispatch, useSelector } from 'react-redux';
import {
  documentTypeToReadable,
  getPageTitle,
  parseDate,
  urlToDocumentType,
  uuidPattern,
} from '../../scripts/utils';
import { getPhysicalLocationsState, getProjectState } from '../../features/project/selectors';
import { getDocumentState } from '../../features/document/selectors';
import { DocumentTemplateType, INumberedDocumentView } from '../../api-client/autogenerated';
import logoSvg from '../../images/wordmark-blue.svg';
import { hasCompletedConstructionSetup } from '../../scripts/store-utils';
import { setProject } from '../../features/project/actions';
import { useHistory } from 'react-router';
import { useEffect, useRef, useState } from 'react';
import { getDocumentsType } from '../../features/documents/selectors';
import { getBidState } from '../../features/bid/selectors';
import { Bid } from '../../features/bid/bid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginLeft: theme.spacing(1),
        textTransform: 'none',
      },
    },
    logo: {
      maxWidth: '120px',
      paddingTop: '2px',
      transition: 'filter .2s',
      '&:hover': {
        filter: 'opacity(85%)',
      },
    },
    typography: {
      body1: {
        fontSize: '8px',
      },
    },
    // style for the last item in the breadcrumb
    lastBreadcrumbItem: {
      borderBottom: '2px solid #F28B00',
    },
    bidconnect: {
      lineHeight: '24px',
      color: '#4E61B0',
      fontSize: 16,
      fontFamily: 'Uniform Rounded Medium',
      letterSpacing: 4,
      marginLeft: 6,
      marginBottom: -2,
    },
  }),
);

type Props = {
  bidSetupName?: string;
};

const getPunchListTitle = (document: INumberedDocumentView) => {
  if (!document.description) {
    return `Punch List ${document.documentNumber}`;
  }

  if (document.description.length > 40) {
    return document.description.slice(0, 40) + '...';
  }

  return document.description;
};

const getTitle = (document: INumberedDocumentView, type: DocumentTemplateType, bid: Bid) => {
  switch (type) {
    case DocumentTemplateType.Submittals:
    case DocumentTemplateType.CloseoutSubmittals:
    case DocumentTemplateType.RequestsForInformation:
    case DocumentTemplateType.AsBuilt:
    case DocumentTemplateType.WarrantyItems:
    case DocumentTemplateType.MiscellaneousDocuments:
      return getPageTitle(type, document);

    case DocumentTemplateType.Drawings:
      return `${documentTypeToReadable[type]} #${document.sheetNumber || document.documentNumber}`;

    case DocumentTemplateType.FieldReports:
      return `Field Report ${
        document.dateOfObservation
          ? `#${parseDate(document.dateOfObservation).format('YYYY-MM-DD')}`
          : `#${document.referenceNumber || document.documentNumber}`
      }`;

    case DocumentTemplateType.Specifications:
      return `${document.submittalSection} ${document.submittalSectionDescription || ''}`;

    case DocumentTemplateType.PunchList:
      return getPunchListTitle(document);

    case DocumentTemplateType.BidTabulation:
      return getPageTitle(type, document, bid);

    default:
      return `${documentTypeToReadable[type]} #${
        document.referenceNumber || document.documentNumber
      }${document.revisionNumber ? `-R${document.revisionNumber}` : ''}`;
  }
};

export default function BreadCrumbs(props: Props) {
  const classes = useStyles();
  const { bidSetupName = '' } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentProject = useSelector(getProjectState);
  const bid = useSelector(getBidState);
  const document = useSelector(getDocumentState);
  const documentType = useSelector(getDocumentsType);
  const locations = useSelector(getPhysicalLocationsState);

  const pathnames = location.pathname
    .split('/')
    .filter((x) => {
      if (documentType === DocumentTemplateType.PunchList)
        return x && x !== 'edit' && x !== 'edit-location';
      return x;
    })
    .map((x) => x.toLowerCase());
  const portal = pathnames[0];
  const [hideBreadCrumbs, toggleBreadCrumbs] = useState(true);
  const ref = useRef<any>();
  const [firstLoad, setFirstLoad] = useState(true);

  function updateWindowDimensions() {
    let newWidth = ref.current?.offsetWidth;
    if (newWidth === undefined) {
      // this is the windows width (which is one of the few variables that isn't undefined on first load)
      // minus padding minus the tools on the right
      newWidth = window.innerWidth - 108 - 396;
      setFirstLoad(false);
    }
    if (newWidth < 750) {
      toggleBreadCrumbs(true);
    } else {
      toggleBreadCrumbs(false);
    }
  }

  function getCase(str: string, prev?: string) {
    if (prev && uuidPattern.test(str)) {
      if (prev === 'comments') return '';
      if (prev === 'projects') return currentProject ? currentProject.name : '...';
      if (prev in urlToDocumentType && (document || bid))
        return getTitle(document!, urlToDocumentType[prev] as DocumentTemplateType, bid);
      if (prev === 'bidding' && bidSetupName) return bidSetupName;
      if (prev === 'punch-list') return locations.find((loc) => loc.id === str)?.name || str;
      if (prev?.includes('conform')) return currentProject?.files?.find((f) => f.id === str)?.name;
      return '';
    }
    if (str === 'bid-submissions') return 'Bid Submissions';
    if (str === 'tasks') return 'Task & Share';
    if (str === 'as-built') return 'As-Built Drawings';
    if (str in urlToDocumentType) return urlToDocumentType[str];
    switch (str) {
      case 'const-mgmt':
        return hasCompletedConstructionSetup() ? 'Construction Management' : 'Construction Setup';
      case 'bid-mgmt':
        return currentProject?.bidSetupId ? 'Bid Management' : 'Bid Setup';
      case 'manage-project':
        return 'Manage Project';
      case 'security-groups':
        return 'Manage Security Groups';
      case 'settings':
        return 'Account Settings';
      case 'users':
        return 'Manage Users';
      case 'project':
        return 'Create Project';
      case 'BIM':
        return 'BIM360';
      case 'site-admin':
        return 'Site Admin Management';
      case 'subscriber-admin':
        return 'Subscriber Admin Management';
      case 'pub-center':
        return 'Publishing and Conforming Center';

      default:
        break;
    }
    return str.replaceAll('-', ' ').replace(/\b\w+/g, (s: string) => {
      return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
    });
  }

  const isBidding = portal === 'bidding';

  const handleGoHome = () => {
    dispatch(setProject());
    history.push(`/${portal}`);
  };

  let previousPath = '';
  return (
    <Breadcrumbs
      ref={ref}
      aria-label="Breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {firstLoad && updateWindowDimensions()}
      {pathnames.length > 1 || isBidding ? (
        <div
          onClick={handleGoHome}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: 3 }}
        >
          <img src={logoSvg} id="centerline-logo" alt="centerline-logo" className={classes.logo} />
          {isBidding && <Typography className={classes.bidconnect}>BidConnect</Typography>}
        </div>
      ) : (
        <img src={logoSvg} alt="centerline-logo" className={classes.logo} />
      )}
      {!hideBreadCrumbs &&
        pathnames.map((value, index) => {
          const previous = previousPath;
          previousPath = value;
          const last = index === pathnames.length - 1;
          let to = `/${pathnames.slice(0, index + 1).join('/')}`;
          if (isBidding) {
            to = `/${portal}/`;
          }

          const displayedValue = getCase(value, previous);

          if (
            displayedValue &&
            value !== 'main' &&
            value !== 'bidding' &&
            value !== 'projects' &&
            value !== 'documents' &&
            value !== 'comments' &&
            value !== 'locked'
          )
            return last ? (
              <Typography color="textPrimary" key={to} className={classes.lastBreadcrumbItem}>
                {value === 'testing' ? 'Testing Reports' : getCase(value, previous)}
              </Typography>
            ) : (
              <Link color="inherit" component={RouterLink} to={to} key={to}>
                {value === 'testing' ? 'Testing Reports' : getCase(value, previous)}
              </Link>
            );
          return undefined;
        })}
      {useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
      })}
    </Breadcrumbs>
  );
}
