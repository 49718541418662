import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import NavAppbar from '../nav-top/NavAppbar';
import PageTitle from '../page-title/PageTitle';
import SettingsAccountForm from './settings/SettingsAccountForm';
import SettingsPasswordForm from './settings/SettingsPasswordForm';
import { Tooltip } from '@material-ui/core';
import { getNotificationsByUserId } from '../../models/api/notifications';
import { useSelector } from 'react-redux';
import { getUserState } from '../../features/user/selectors';
import { FormatListBulletedOutlined } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import UserActivityDialog from '../dialogs/UserActivityDialog';
import SettingsNotificationForm from './settings/SettingsNotificationForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      height: '100%',
      boxShadow: '1px 1px 10px 1px rgba(0, 0, 0, 0.1)',
    },
    margin: {
      margin: theme.spacing(1),
      height: '70%', // make buttons same height
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: '100vh',
      overflowX: 'hidden',
    },
    alignLeft: {
      textAlign: 'left',
    },
  }),
);

interface SettingsPageProps {
  isBiddingPortal?: boolean;
}

export default function SettingsPage(props: SettingsPageProps) {
  const classes = useStyles();
  const { isBiddingPortal } = props;

  const user = useSelector(getUserState);

  const [totalNotifications, setTotalNotifications] = useState<number>();
  const [userActivityDialogOpen, setUserActivityDialogOpen] = useState(false);

  useEffect(() => {
    if (!isBiddingPortal) {
      getNotificationsByUserId(user.id, undefined, 0).then(({ total }) => {
        setTotalNotifications(total);
      });
    }
  }, []);

  return (
    <main className={classes.content}>
      <NavAppbar isBiddingPortal={isBiddingPortal} />
      <Grid container>
        <Grid item xs={10}>
          <PageTitle title="Settings" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={10}>
          <Paper className={classes.paper}>
            {!isBiddingPortal ? (
              <>
                {/*<h2 className={classes.alignLeft}>Account Info</h2>*/}
                <div
                  style={{ display: 'flex', alignItems: 'center', marginTop: 8, marginBottom: 16 }}
                >
                  {/*<Typography variant="body1">*/}
                  {/*  Your Activity: {totalNotifications} Notifications*/}
                  {/*</Typography>*/}
                  <h2 className={classes.alignLeft} style={{ margin: 0, lineHeight: '28px' }}>
                    Account Activity: {totalNotifications} Actions
                  </h2>
                  <Tooltip arrow placement="top" title="View Activity Log">
                    <IconButton
                      onClick={() => setUserActivityDialogOpen(true)}
                      style={{ padding: 0, marginLeft: 16 }}
                    >
                      <FormatListBulletedOutlined />
                    </IconButton>
                  </Tooltip>
                </div>
                <Divider light style={{ marginBottom: 25, marginTop: 10 }} />
              </>
            ) : null}
            <h2 className={classes.alignLeft}>Account Info</h2>
            <SettingsAccountForm />
            <Divider light style={{ marginBottom: 25, marginTop: 10 }} />
            <h2 className={classes.alignLeft}>Login Credentials</h2>
            <SettingsPasswordForm />
            <Divider light style={{ marginBottom: 25, marginTop: 10 }} />

            <h2 className={classes.alignLeft}>Notification Preferences</h2>
            <SettingsNotificationForm />
          </Paper>
        </Grid>
      </Grid>
      {!isBiddingPortal ? (
        <UserActivityDialog
          open={userActivityDialogOpen}
          handleClose={() => setUserActivityDialogOpen(false)}
          userId={user.id}
          name={user.name}
        />
      ) : null}
    </main>
  );
}
